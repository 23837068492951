.cta-platforms {
  & a {
    display: block;
    width: 1.5rem;
    text-align: center;
    color: #333;
    text-decoration: none;

    & img {
      filter: brightness(1) grayscale(1);
      width: 100%;
      transition: all 0.3s ease-in-out;
    }

    & span {
      display: none;
    }

    &.android {
      & img {
        filter: brightness(0.7) grayscale(1);
      }
    }

    &:hover {
      & img {
        filter: brightness(1) grayscale(0);
      }
    }
  }
}

.cta-button {
  padding: 15px 40px;
  border: none;
  outline: none;
  color: #fff;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 3rem;
  font-weight: 300;
  filter: drop-shadow(0px 0px 12px rgb(0, 80, 255));
  text-decoration: none;
  text-align: center;

  &.cta-button-non-safari {
    filter: none;
  }
}
.cta-button::after {
  content: '';
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  left: 0;
  top: 0;
  border-radius: 3rem;
  transition: background-color 0.3s ease-in-out;
}

.cta-button-non-safari::before {
  content: '';
  background: linear-gradient(
    45deg,
    #0050ff,
    #5087ff,
    #58b3eb,
    #5087ff,
    #0050ff
  );
  position: absolute;
  top: -2px;
  left: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  background-size: 600%;
  z-index: -1;
  filter: blur(8px);
  animation: glowing 20s linear infinite;
  transition: opacity 0.3s ease-in-out, top 0.6s ease-in-out,
    left 0.6s ease-in-out, width 0.6s ease-in-out, height 0.6s ease-in-out;
  border-radius: 3rem;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.cta-button-non-safari:hover::before,
.cta-button-non-safari:focus::before {
  top: -6px;
  left: -6px;
  width: calc(100% + 12px);
  height: calc(100% + 12px);
}

.cta-button:focus-visible::after {
  outline: 2px solid white;
  background-color: #444;
}

.cta-button:active::after {
  background-color: #444;
}
