@import './colors';

$v-btn-shadow-spread: 6px;
$v-btn-shadow-v-offset: 3px;
$v-border-radius: 8px;
$v-small-font: 90%;

// Light
$v-light-color-body-bg: rgb(255, 255, 255);
$v-light-color-hover-bg: rgba(230, 230, 230, 0.5);
$v-light-color-active-bg: rgba(230, 230, 230, 0.2);
$v-light-color-body-bg-rgb: 255, 255, 255;
$v-light-color-body-bg-transparent: rgba(255, 255, 255, 0);
$v-light-color-primary: #0050ff;
$v-light-color-primary-rgb: 0, 80, 255;
$v-light-color-active: #0047e3;
$v-light-color-danger: #ff5e5e;
$v-light-color-danger-active: #e13a3a;
$v-light-color-body: #6a6a6a;
$v-light-color-muted: #bababa;
$v-light-color-body-rgb: 106, 106, 106;
$v-light-color-emphasize: #000000;
$v-light-color-contrast: #fbfbfb;
$v-light-color-contrast-2: #fcfcfc;
$v-light-color-border: #bababa;
$v-light-color-border-muted: #e6e6e6;

$v-light-btn-color: #ffffff;
$v-light-btn-bg: $v-light-color-primary;
$v-light-btn-hover-bg: $v-light-color-active;
$v-light-btn-active-bg: $v-light-color-active;
$v-light-btn-disabled-bg: $v-light-color-muted;
$v-light-btn-active-shadow: inset 0px 0px 15px rgba(3, 36, 108, 0.4);
$v-light-btn-box-shadow: 0 $v-btn-shadow-v-offset $v-btn-shadow-spread
  rgba(0, 80, 255, 0.25);

// Dark
$v-dark-color-body-bg: rgb(37, 37, 37);
$v-dark-color-body-bg-rgb: 37, 37, 37;
$v-dark-color-body-bg-transparent: rgba(37, 37, 37, 0);
$v-dark-color-primary: #28a5ff;
$v-dark-color-primary-rgb: 40, 165, 255;
$v-dark-color-active: #0094ff;
$v-dark-color-danger: #ff5e5e;
$v-dark-color-body: #bababa;
$v-dark-color-body-rgb: 186, 186, 186;
$v-dark-color-emphasize: #ffffff;
$v-dark-color-contrast: #3b3b3b;
$v-dark-color-contrast-2: #424242;
$v-dark-color-border: #6a6a6a;
$v-dark-color-border-muted: #e6e6e6;

$v-dark-btn-color: #ffffff;
$v-dark-btn-bg: $v-dark-color-primary;
$v-dark-btn-hover-bg: $v-dark-color-active;
$v-dark-btn-active-bg: $v-dark-color-active;
$v-dark-btn-active-shadow: inset 0px 0px 15px rgba(3, 36, 108, 0.4);
$v-dark-btn-box-shadow: 0 $v-btn-shadow-v-offset $v-btn-shadow-spread
  rgba(40, 165, 255, 0.4);

$dark-theme: (
  primary-color: $v-dark-color-primary,
  on-primari-color: #fff,
  primary-rgb: $v-dark-color-primary-rgb,
  active-color: $v-dark-color-active,
  danger-color: $v-dark-color-danger,
  emphasize-color: $v-dark-color-emphasize,
  body-bg: $v-dark-color-body-bg,
  body-bg-transparent: $v-dark-color-body-bg-transparent,
  text-color: $v-dark-color-body,
  text-muted: $v-gray-600,
  contrast-bg: $v-dark-color-contrast,
  contrast-bg-hover: lighten($v-dark-color-contrast-2, 5%),
  border-color: $v-dark-color-border,

  button-bg: $v-dark-color-primary,
  button-bg-hover: $v-dark-color-active,
  button-active-bg: $v-dark-btn-active-bg,
  button-shadow: $v-dark-btn-box-shadow,
  button-active-shadow: $v-dark-btn-active-shadow,
  button-padding: 0.5rem 2rem,
  button-color: $v-dark-btn-color,

  form-border-color: $v-dark-color-border,
  form-border-color-focus: $v-dark-color-border,
  form-background-color: $v-dark-color-contrast-2,
  form-background-color-focus: lighten($v-dark-color-contrast-2, 5%),
  toast-background: $v-gray-850,
  toast-button-color: $v-gray-100,
  toast-color: $v-gray-200,

  product-card-shadow-color: rgba(0, 0, 0, 0.69),
  highlight-color: #6689b0,
);

$light-theme: (
  primary-color: $v-light-color-primary,
  on-primari-color: #fff,
  primary-rgb: $v-light-color-primary-rgb,
  active-color: $v-light-color-active,
  danger-color: $v-light-color-danger,
  emphasize-color: $v-light-color-emphasize,
  body-bg: $v-light-color-body-bg,
  body-bg-transparent: $v-light-color-body-bg-transparent,
  text-color: $v-light-color-body,
  text-muted: $v-gray-600,
  contrast-bg: $v-light-color-contrast,
  contrast-bg-hover: darken($v-light-color-contrast, 5%),
  border-color: $v-light-color-border,

  button-bg: $v-light-color-primary,
  button-bg-hover: $v-light-color-active,
  button-active-bg: $v-light-btn-active-bg,
  button-shadow: $v-light-btn-box-shadow,
  button-active-shadow: $v-light-btn-active-shadow,
  button-padding: 0.5rem 2rem,
  button-color: $v-light-btn-color,

  form-border-color: $v-light-color-border,
  form-border-color-focus: $v-light-color-border,
  form-background-color: $v-light-color-contrast-2,
  form-background-color-focus: lighten($v-light-color-contrast-2, 5%),
  toast-background: $v-gray-850,
  toast-button-color: $v-gray-100,
  toast-color: $v-gray-200,

  product-card-shadow-color: rgba(0, 0, 0, 0.69),
  highlight-color: #b4d7ff,
);
