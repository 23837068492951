$font-family-base: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  Roboto, 'Helvetica Neue', Arial, sans, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol', 'Noto Color Emoji';

$headings-font-family: 'Montserrat', sans-serif;

$primary: $v-light-color-primary;
$danger: $v-light-color-danger;
$body-color: $v-light-color-body;

$font-weight-normal: 300;
$headings-font-weight: 500;

$navbar-brand-height: 2.2rem;
$navbar-padding-y: 1rem;

$font-size-base: 1.5rem;

$navbar-toggler-focus-width: 1px;

$hover-background: $v-light-color-active;

$enable-shadows: true;
$btn-box-shadow: $v-light-btn-box-shadow;

$border-radius: $v-border-radius;
$border-color: $v-light-color-border;

$card-border-color: $v-light-color-border;
$card-border-width: 0.5px;
$card-cap-bg: $v-light-color-contrast;
