//
// Footer
//

// Styling for links in the footer
footer {
  a {
    color: fade-out($white, 0.3);
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
      color: fade-out($white, 0.1);
      text-decoration: underline;
    }
  }
}

.bg-gray {
  background-color: $gray-800;
}
