html {
  scroll-padding-top: 4.5rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: $v-light-color-emphasize;
}

a {
  text-decoration-thickness: 0.5px !important;
  text-underline-offset: 2px !important;
}

