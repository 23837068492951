//
// Masthead
//

.masthead {
  background-color: $v-light-color-contrast;
  // Set top padding to 5rem plus the height of the fixed navbar
  padding-top: 3rem;
  padding-bottom: 5rem;
}
